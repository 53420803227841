import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, Fade } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

import { globalGetService } from '../../../utils/globalApiServices';
import { convertTimeWithTimezone } from '../../../utils/time';
import { remapTCRStatus } from '../utils';
import { MIGRATION_STATUS_LABEL_MAP } from '../constants';

export default function CampaignBasicDetails(props) {
  const { campaign, migrationInfo } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [maxSubUsecase, setMaxSubusecase] = useState('');

  useEffect(() => {
    globalGetService(`enums/usecaseTypes`).then((response) => {
      const usecaseItem =
        response.data &&
        response.data.find((item) => item.id == campaign.usecase);
      setMaxSubusecase(usecaseItem.maxSubUsecases);
    });
  }, []);

  const handleClick = (e) => {
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const handleRequestClose = (e) => {
    setOpen(false);
  };

  const getMigrationStatus = () => {
    const { status = 'No' } = migrationInfo ?? {};
    return MIGRATION_STATUS_LABEL_MAP[status] ?? status;
  };

  return (
    <div className="basic-details-wrapper" data-testid="campaignBasicDetails">
      <h3
        className="text-center heading1 campaignId"
        data-testid="campaignBasicDetailsCampaignId"
      >{`Campaign ID: ${campaign.uid}`}</h3>
      <ul className="list-inline basic-details-list">
        <div className="w-50">
          <div className="column">
            <li>
              <h3
                className="heading2"
                data-testid="campaignBasicDetailsCreateDate"
              >
                <span>Registered on:</span>
                {convertTimeWithTimezone(campaign.createDate, 'MM/DD/YYYY')}
              </h3>
            </li>
            <li>
              {campaign.subUsecases ? (
                <div className="campaign-usecase-wrapper">
                  <h3
                    className="heading2"
                    aria-owns={open ? 'simple-menu' : null}
                    aria-haspopup="true"
                    // onMouseOver={handleClick}
                    data-testid="campaignBasicDetailsUseCase"
                  >
                    <span>Use-Case:</span>

                    <span onClick={handleClick} className="val">
                      {campaign.usecase}
                      {` ${campaign.subUsecases.split(',').length}/`}
                      <span style={{ color: '#FE772D', fontWeight: '700' }}>
                        {maxSubUsecase}
                      </span>
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className={`dropdown-arrow ${open ? 'open' : ''}`}
                      />
                    </span>
                  </h3>
                  <Menu
                    id="simple-menu"
                    data-testid="campaignBasicDetailsMenu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleRequestClose}
                    className="campaign-subusecases-menu"
                    MenuListProps={
                      {
                        // onMouseLeave: handleRequestClose
                      }
                    }
                    TransitionComponent={Fade}
                  >
                    {campaign.subUsecases.split(',').map((sub, index) => (
                      <MenuItem
                        key={index}
                        onClick={handleRequestClose}
                        data-testid={`campaignBasicDetailsMenuItem-${sub}`}
                      >
                        {sub}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              ) : (
                <h3
                  className="heading2"
                  data-testid="campaignBasicDetailsUseCase"
                >
                  <span>Use-Case:</span>
                  {campaign.usecase}
                </h3>
              )}
            </li>
          </div>
        </div>
        <div className="w-50 border-left">
          <div className="column">
            <li>
              <h3
                className="heading2"
                data-testid="campaignBasicDetailsTcrStatus"
              >
                <span>TCR Status:</span>
                <span
                  className={`${
                    remapTCRStatus(campaign.status) === 'ACTIVE'
                      ? 'primary-color'
                      : remapTCRStatus(campaign.status) === 'DEACTIVATED'
                      ? 'error-color'
                      : ''
                  }`}
                >
                  {remapTCRStatus(campaign.status, true)}
                </span>
              </h3>
            </li>
            <li>
              <h3
                className="heading2"
                data-testid="campaignBasicDetailsMigration"
              >
                <span className="title">Migration:</span>
                <span className="val primary-color">
                  {getMigrationStatus()}
                </span>
              </h3>
            </li>
          </div>
        </div>
      </ul>
    </div>
  );
}
