import axios from 'axios';

import { extractErrorMessages } from './utils';
import { toastFlashMessage } from '..';
import { DEV_BASE_URL } from '../../constants';

const instance = axios.create();
instance.defaults.baseURL = DEV_BASE_URL;
instance.interceptors.request.use(
  (config) => {
    const item = localStorage.getItem('okta-token-storage');
    if (item) {
      const oktaTokenStorage = JSON.parse(item);
      if (oktaTokenStorage) {
        config.headers.Authorization = `Bearer ${oktaTokenStorage.accessToken.accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      response.ok = true;
    }
    return response;
  },
  (error) => {
    const { config } = error;
    if (error.response && error.response.status < 500) {
      const { data } = error.response;
      if (!config.silent && data?.error && Object.keys(data.error)) {
        const messages = extractErrorMessages(data.error);
        toastFlashMessage(messages, 'error');
      }
      return error.response;
    }

    if (!config.silent) {
      toastFlashMessage(
        'Unable to process the request due to technical error',
        'error'
      );
    }
    return error;
  }
);

export default instance;
