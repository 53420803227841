export enum Role {
  Viewer = 'ROLE_VIEWER',
  Reviewer = 'ROLE_REVIEWER',
  User = 'ROLE_USER',
  Manager = 'ROLE_MANAGER',
}

export type User = {
  id: string;
  status: string;
  created: number;
  statusChanged: number;
  lastLogin?: number;
  lastUpdated: number;
  profile: {
    firstName: string;
    lastName: string;
    email: string;
    dcaUid: string;
    dcaUserRole?: Role[];
    fullName: string;
  };
};

export type DcaProfile = {
  uid: string;
  displayName: string;
  escalateEmail: string;
  companyLogo?: string;
  osrProvisionerId?: string;
  createDate: number;
  updateDate: number;
  mfaEnabled: boolean;
  maxPortalUsers: number;
  dpaSigned: boolean;
  soleProprietorEnabled: boolean;
  dca2AutoAccept: boolean;
};
