import React, { useState, useEffect } from 'react';
import { Grid, Menu, MenuItem, Fade, makeStyles } from '@material-ui/core';
import {
  globalGetService,
  globalPutService,
} from '../../../utils/globalApiServices';
import { toastFlashMessage } from '../../../utils';
import CampaignSuspendDialog from './CampaignSuspendDialog';
import { suspendCampaign, unsuspendCampaign } from '../apis';
import CampaignLiftSuspensionDialog from './CampaignLiftSuspensionDialog';

import { Box, RoleGuard } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAward,
  faShareNodes,
  faEllipsisVertical,
  faAngleDown,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons';
import { getMnoStatusIcon } from '../../../utils/statusIcon';

const useStyles = makeStyles({
  actionItem: {
    display: 'flex',
    flexDirection: 'column',
    width: 77,
    height: '100%',
    '& > .label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 !important',
    },
    '& > .value': {
      padding: '5px 0 !important',
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderLeft: '1px solid #C0CCD4',
    boxSizing: 'border-box',
  },
  menuButton: {
    width: '15px',
    height: '15px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  shareButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '26px',
    padding: '0 11px',
    border: 'none',
    borderRadius: 200,
    color: '#FFFFFF',
    background: '#00698F',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    cursor: 'pointer',
    '&.disabled': {
      opacity: 0.4,
    },
  },
  menuContainer: {
    boxShadow: '4px 6px 10px -1px rgba(0, 0, 0, 0.25) !important',
  },
  menu: {
    padding: '5px 0',
    background: '#FFFFFF !important',
    '& > .MuiMenuItem-root': {
      color: '#68737A !important',
    },
  },
  menuItem: {
    '&:hover': {
      background: '#EBEDED',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 118,
    height: '100%',
    background: '#F6F7F7',
  },
  suspendButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 26,
    padding: '0 10px',
    border: 'none',
    borderRadius: 100,
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400,
    color: '#FFFFFF',
    background: '#FE772D',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.4,
      pointerEvent: 'none',
      cursor: 'not-allowed',
    },
  },
  liftSuspensionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 26,
    padding: '0 10px',
    border: 'none',
    borderRadius: 100,
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400,
    color: '#FFFFFF',
    background: '#00698F',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.4,
      pointerEvent: 'none',
      cursor: 'not-allowed',
    },
  },
});

export default function NetworkItem(props) {
  const {
    wideButton,
    profile,
    carrier,
    campaign,
    onDcaMenuClick,
    existingChain,
    provisional,
  } = props;
  const [dcaOptions, setDcaOptions] = useState([]);
  const [secondaryDca, setSecondaryDca] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(false);
  const [suspendModalOpen, setSuspendModalOpen] = useState(false);
  const [suspendModalDisabled, setSuspendModalDisabled] = useState(false);
  const [liftSuspensionModalOpen, setLiftSuspensionModalOpen] = useState(false);
  const [liftSuspensionModalDisabled, setLiftSuspensionModalDisabled] =
    useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [editing, setEditing] = useState(false);
  const classes = useStyles();
  const mno = carrier.rulesEngineResultsJson
    ? carrier.rulesEngineResultsJson.mno
    : '--';
  const isExpired = campaign.status === 'EXPIRED';

  const handleClick = (e) => {
    // if(role == "ROLE_REVIEWER"){
    //     setAnchorEl(null)
    // }else{
    //     setAnchorEl(e.currentTarget)
    // }
    setAnchorEl(e.currentTarget);
    onDcaMenuClick && onDcaMenuClick();
  };

  const handleMenuButtonClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleSuspendClick = () => {
    setMenuAnchorEl(null);
    setSuspendModalOpen(true);
  };

  const handleLiftSuspensionClick = () => {
    setMenuAnchorEl(null);
    setLiftSuspensionModalOpen(true);
  };

  const handleUpdateDcaClick = () => {
    setMenuAnchorEl(null);
    setEditing(true);
  };

  const handleRequestClose = (e) => {
    setAnchorEl(null);
  };

  const fetchDcaListApi = (query = {}) => {
    globalGetService(`dca/dcas`, query).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        const options = response.data.map((record) => {
          return { label: record.displayName, value: record.uid };
        });
        if (options.length === 0) {
          setDcaOptions([
            {
              disabled: true,
              label:
                'Based on the use-case there is no Secondary DCA eligible for selection. Contact your CNP for details.',
              value: '0',
            },
          ]);
        } else {
          setDcaOptions(options);
        }
      }
    });
  };

  const handleElectSecondaryDca = (e) => {
    e.preventDefault();
    if (secondaryDca) {
      setLoader(true);
      globalPutService(
        `dca/campaigns/primaryDca/${campaign.uid}/secondaryDca/${secondaryDca}`,
        { mnoIds: [carrier.networkId], isProvisional: provisional }
      ).then((response) => {
        setLoader(false);
        setEditing(false);
        if (response.status >= 200 && response.status < 300) {
          props.handleUpdateCampaignOperations();
          toastFlashMessage('Secondary DCA elected successfully', 'success');
        }
      });
    }
  };
  const isDirectCarrier = () => {
    if (carrier && carrier.dcas) {
      for (const dca of carrier.dcas) {
        if (dca.id === profile.uid) {
          return true;
        }
      }
    }
    return false;
  };
  const allowSuspendOrUnsuspend = () => {
    const status = carrier.status.toLowerCase();
    return (
      status === 'approved' || status === 'registered' || status === 'suspended'
    );
  };

  const findLastDca = () => {
    let lastDca = null;
    if (carrier && carrier.dcas && carrier.dcas.length > 0) {
      let lastTimestamp = carrier.dcas[0].createDate - 1;
      for (let i = 0; i < carrier.dcas.length; i++) {
        const dca = carrier.dcas[i];
        if (dca.createDate > lastTimestamp) {
          lastDca = dca;
          lastTimestamp = dca.createDate;
        }
      }
    }
    return lastDca;
  };
  const isNotDeclinedByDca = () => {
    const lastDca = findLastDca();
    return lastDca && lastDca.status.toLowerCase() !== 'declined';
  };
  const getLastDcaName = () => {
    const lastDca = findLastDca();
    return lastDca ? lastDca.name : 'N/A';
  };
  const getLastDcaStatus = () => {
    const lastDca = findLastDca();
    const lastStatus = lastDca ? lastDca.status.toLowerCase() : '';
    return lastDca && (lastStatus === 'pending' || lastStatus === 'accepted')
      ? `${lastDca.status[0]}${lastDca.status.slice(1).toLowerCase()}`
      : 'N/A';
  };
  const isResponsibleForMno = () => {
    const lastDca = findLastDca();
    return (
      lastDca &&
      (lastDca.id === profile.uid || lastDca.status.toLowerCase() === 'pending')
    );
  };
  const isDcaPending = () => {
    const lastDca = findLastDca();
    return lastDca ? lastDca.status.toLowerCase() === 'pending' : false;
  };

  useEffect(() => {
    fetchDcaListApi({
      mnoId: carrier.networkId,
      soleProprietorEnabled:
        campaign.usecase === 'SOLE_PROPRIETOR' ? true : undefined,
      acceptCampaignShare: true,
      cnpMigrationSupported: provisional ? true : undefined,
    });
  }, [provisional, carrier, campaign]);
  return (
    <Grid container className="" data-testid="networkItem">
      <Grid item xs={1} data-testid="networkItemMno">
        <div className="name flex-centered">
          <p>{mno}</p>
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        style={campaign.isPrimaryDca ? { maxWidth: '12%' } : {}}
        data-testid="networkItemQualify"
      >
        <div className="label">
          <h3 className="supportText">
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box margin={{ right: 'xxs' }}>
                <FontAwesomeIcon icon={faAward} size="xl" />
              </Box>
              <span>QUALIFY</span>
            </Box>
          </h3>
        </div>
        <div className="value">
          <p className="paragraph medium">
            {carrier.rulesEngineResultsJson
              ? carrier.rulesEngineResultsJson.qualify
                ? 'Yes'
                : 'No'
              : '--'}
          </p>
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        style={campaign.isPrimaryDca ? { maxWidth: '12%' } : {}}
        data-testid="networkItemMnoReview"
      >
        <div className="label">
          <h3 className="supportText">
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box margin={{ right: 'xxs' }}>
                <FontAwesomeIcon icon={faThumbsUp} size="xl" />
              </Box>
              <span>MNO REVIEW</span>
            </Box>
          </h3>
        </div>
        <div className="value">
          <p className="paragraph medium">
            {carrier.rulesEngineResultsJson
              ? carrier.rulesEngineResultsJson.mnoReview
                ? 'Yes'
                : 'No'
              : '--'}
          </p>
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          marginLeft: 5,
          maxWidth: campaign.isPrimaryDca ? '13%' : '15.66%',
        }}
        data-testid="networkItemCampaignStatus"
      >
        <div className="label">
          <h3 className="supportText">
            <span>CAMPAIGN STATUS</span>
          </h3>
        </div>
        <div className="value">
          <p className="paragraph medium">
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box margin={{ right: 'xxs' }}>
                {getMnoStatusIcon(carrier.status.toLowerCase(), { size: 'lg' })}
              </Box>
              <span>
                {carrier.status.toLowerCase() === 'approved'
                  ? 'Registered'
                  : carrier.status.toLowerCase()}
              </span>
            </Box>
          </p>
        </div>
      </Grid>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 8,
          maxWidth: campaign.isPrimaryDca ? '12%' : '42.1%',
        }}
        item
        xs={campaign.isPrimaryDca ? 2 : 6}
        data-testid="networkItemMnoName"
      >
        <div style={{ flex: 1, height: '100%' }}>
          <div className="label">
            <h3 className="supportText">
              <span>
                {carrier.rulesEngineResultsJson
                  ? carrier.rulesEngineResultsJson.mno === 'T-Mobile'
                    ? 'BRAND TIER'
                    : 'MESSAGE CLASS'
                  : '--'}
              </span>
            </h3>
          </div>
          <div className="value">
            <p className="paragraph medium">
              {carrier.rulesEngineResultsJson
                ? carrier.rulesEngineResultsJson.mno === 'T-Mobile'
                  ? carrier.rulesEngineResultsJson.brandTier
                  : carrier.rulesEngineResultsJson.msgClass || 'N/A'
                : '--'}
            </p>
          </div>
        </div>
        {!campaign.isPrimaryDca && allowSuspendOrUnsuspend() && (
          <div
            className={classes.buttonContainer}
            style={{ width: wideButton ? 118 : 81 }}
          >
            {carrier.status.toLowerCase() === 'suspended' ? (
              <RoleGuard
                feature="campaignDetail.liftSuspendMnoStatus"
                render={(hasPermission) => {
                  return (
                    <button
                      className={classes.liftSuspensionButton}
                      disabled={!existingChain || !hasPermission || isExpired}
                      onClick={() => setLiftSuspensionModalOpen(true)}
                    >
                      Lift Suspension
                    </button>
                  );
                }}
              />
            ) : (
              <RoleGuard
                feature="campaignDetail.suspendMnoStatus"
                render={(hasPermission) => {
                  return (
                    <button
                      className={classes.suspendButton}
                      disabled={!existingChain || !hasPermission || isExpired}
                      onClick={() => setSuspendModalOpen(true)}
                    >
                      Suspend
                    </button>
                  );
                }}
              />
            )}
          </div>
        )}
      </Grid>
      {campaign.isPrimaryDca ? (
        <>
          <Grid
            item
            xs={2}
            style={{ maxWidth: '18%' }}
            data-testid="networkItemShareStatus"
          >
            <div className="label">
              <h3 className="supportText">
                <span>SECONDARY DCA SHARING STATUS</span>
              </h3>
            </div>
            <div className="value">
              <p className="paragraph medium">
                <span>{getLastDcaStatus()}</span>
              </p>
            </div>
          </Grid>

          <Grid style={{ display: 'flex', flexDirection: 'row' }} item xs={3}>
            {isNotDeclinedByDca(carrier) && !editing ? (
              <>
                <div style={{ flex: 1 }} data-testid="networkItemDcaStatus">
                  <div className="label sharingStatus">
                    <h3 className="supportText">
                      <span>DCA NAME</span>
                    </h3>
                  </div>
                  <div className="value sharingStatus">
                    <p className="paragraph medium">
                      <Box flexDirection="row">
                        <Box margin={{ right: 'xxs' }}>
                          <FontAwesomeIcon icon={faShareNodes} />
                        </Box>
                        <span>{`DCA: ${getLastDcaName()}`}</span>
                      </Box>
                    </p>
                  </div>
                </div>
                <div className={classes.actionItem}>
                  <div className="label">
                    <h3 className="supportText">
                      <span>ACTION</span>
                    </h3>
                  </div>
                  <div className="value">
                    <div className={classes.actionContainer}>
                      <button
                        className={classes.menuButton}
                        onClick={handleMenuButtonClick}
                        data-testid="networkItemActionMenu"
                      >
                        <FontAwesomeIcon
                          icon={faEllipsisVertical}
                          style={{ color: 'FE772D' }}
                        />
                      </button>
                      <Menu
                        TransitionComponent={Fade}
                        classes={{
                          paper: classes.menuContainer,
                          list: classes.menu,
                        }}
                        open={!!menuAnchorEl}
                        anchorEl={menuAnchorEl}
                        onClose={() => setMenuAnchorEl(null)}
                        data-testid="networkItemMenu"
                      >
                        {isDirectCarrier() &&
                          (carrier.status.toLowerCase() === 'suspended' ? (
                            <RoleGuard
                              feature="campaignDetail.liftSuspensionCarrierStatus"
                              render={(hasAccess) => {
                                return (
                                  <MenuItem
                                    classes={{ root: classes.menuItem }}
                                    disabled={
                                      !existingChain ||
                                      !isResponsibleForMno() ||
                                      isExpired ||
                                      !hasAccess
                                    }
                                    onClick={handleLiftSuspensionClick}
                                    data-testid="networkItemMenuItemLiftSuspension"
                                  >
                                    Lift Suspension
                                  </MenuItem>
                                );
                              }}
                            />
                          ) : (
                            <RoleGuard
                              feature="campaignDetail.suspendCarrierStatus"
                              render={(hasAccess) => {
                                return (
                                  <MenuItem
                                    classes={{ root: classes.menuItem }}
                                    disabled={
                                      !existingChain ||
                                      !isResponsibleForMno() ||
                                      isExpired ||
                                      !hasAccess
                                    }
                                    onClick={handleSuspendClick}
                                    data-testid="networkItemMenuItemSuspend"
                                  >
                                    Suspend
                                  </MenuItem>
                                );
                              }}
                            />
                          ))}
                        <RoleGuard
                          feature="campaignDetail.editDcaCarrierStatus"
                          render={(hasAccess) => {
                            return (
                              <MenuItem
                                classes={{ root: classes.menuItem }}
                                disabled={
                                  isDcaPending() || isExpired || !hasAccess
                                }
                                onClick={handleUpdateDcaClick}
                                data-testid="networkItemMenuItemUpdateDca"
                              >
                                Update DCA
                              </MenuItem>
                            );
                          }}
                        />
                      </Menu>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div style={{ flex: 1 }}>
                  <div className="label sharingStatus">
                    <h3 className="supportText">
                      <span>DCA NAME</span>
                    </h3>
                  </div>
                  <div className="elect-secondary-dca">
                    <Grid container>
                      <Grid item xs={12} className="space-between">
                        <div className="secondary-dca-select">
                          <RoleGuard
                            feature="campaignDetail.selectDcaCarrierStatus"
                            render={(hasPermission) => {
                              return (
                                <div
                                  aria-owns={'simple-menu'}
                                  aria-haspopup="true"
                                  onClick={handleClick}
                                  // onMouseOver={handleClick}
                                  className={`secondary-dca-select-button ${
                                    !hasPermission || isExpired
                                      ? 'disabled'
                                      : ''
                                  }`}
                                  data-testid="networkItemMenuButton"
                                >
                                  <Box flexDirection="row">
                                    <Box margin={{ right: 'xxs' }}>
                                      <FontAwesomeIcon
                                        icon={faShareNodes}
                                        style={{ color: 'white' }}
                                      />
                                    </Box>
                                    <span>
                                      {secondaryDca
                                        ? dcaOptions.find(
                                            (option) =>
                                              option.value === secondaryDca
                                          ).label
                                        : 'Select a DCA'}
                                    </span>
                                  </Box>
                                  <FontAwesomeIcon
                                    icon={faAngleDown}
                                    style={{ color: 'white' }}
                                  />
                                </div>
                              );
                            }}
                          />
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleRequestClose}
                            className="secondary-dca-menu"
                            TransitionComponent={Fade}
                            data-testid="networkItemMenu"
                          >
                            {dcaOptions.map((option, index) => (
                              <MenuItem
                                data-testid={`networkItemMenu-${option.label}`}
                                disabled={option.disabled}
                                value={option.value}
                                onClick={(e) => {
                                  setSecondaryDca(option.value);
                                  setAnchorEl(null);
                                }}
                                key={index}
                                className={
                                  option.value === secondaryDca
                                    ? 'selected'
                                    : ''
                                }
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={classes.actionItem}>
                  <div className="label">
                    <h3 className="supportText">
                      <span>ACTION</span>
                    </h3>
                  </div>
                  <div className="value">
                    <div className={classes.actionContainer}>
                      <RoleGuard
                        feature="campaignDetail.selectShareCarrierStatus"
                        render={(hasAccess) => {
                          return (
                            <button
                              data-testid="networkItemShareButton"
                              className={`${classes.shareButton} ${
                                !secondaryDca ||
                                loader ||
                                !hasAccess ||
                                isExpired
                                  ? 'disabled'
                                  : ''
                              }`}
                              onClick={handleElectSecondaryDca}
                            >
                              Share
                            </button>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </Grid>
        </>
      ) : null}
      {suspendModalOpen && (
        <CampaignSuspendDialog
          disabled={suspendModalDisabled}
          profile={profile}
          campaign={campaign}
          carrier={carrier}
          onClose={() => setSuspendModalOpen(false)}
          onSubmit={async (explanation, suspensionCategory) => {
            try {
              setSuspendModalDisabled(true);
              await suspendCampaign(
                campaign.uid,
                carrier.networkId,
                explanation,
                suspensionCategory
              );
              setSuspendModalOpen(false);
              toastFlashMessage(`${mno} Successfully Suspended`, 'success');
              props.handleUpdateCampaignOperations();
            } catch (e) {
            } finally {
              setSuspendModalDisabled(false);
            }
          }}
        />
      )}
      {liftSuspensionModalOpen && (
        <CampaignLiftSuspensionDialog
          disabled={liftSuspensionModalDisabled}
          profile={profile}
          campaign={campaign}
          carrier={carrier}
          onClose={() => setLiftSuspensionModalOpen(false)}
          onSubmit={async (explanation) => {
            try {
              setLiftSuspensionModalDisabled(true);
              await unsuspendCampaign(
                campaign.uid,
                carrier.networkId,
                explanation
              );
              setLiftSuspensionModalOpen(false);
              toastFlashMessage(`${mno} Suspension Lifted`, 'success');
              props.handleUpdateCampaignOperations();
            } catch (e) {
            } finally {
              setLiftSuspensionModalDisabled(false);
            }
          }}
        />
      )}
    </Grid>
  );
}
