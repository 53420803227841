import {
  globalDeleteService,
  globalGetService,
  globalPostService,
  globalPutService,
} from '../../utils/globalApiServices';
import { getTimezone } from '../../utils/time';

export const getCampaignBrandMNOSuspensionApi = async (brandUid) => {
  const response = await globalGetService(
    `dca/brands/${brandUid}/mnoSuspensionStatus`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const getMNOMetaDataApi = async () => {
  const response = await globalGetService('enums/mno');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const getCampaignAttachmentsApi = async (campaignUid, folder) => {
  const response = await globalGetService(
    `dca/attachments/campaign/${campaignUid}?folder=${encodeURIComponent(
      folder
    )}`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export const downloadAttachmentApi = async (attachmentUuid) => {
  const response = await globalGetService(
    `dca/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    { responseType: 'arraybuffer' }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const downloadAttachmentToDesktop = async (attachmentUuid, fileName) => {
  const response = await globalGetService(
    `dca/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    { responseType: 'blob' }
  );
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const getAllCnps = async () => {
  const response = await globalGetService('/dca/cnp');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export const getAllUsecaseTypes = async () => {
  const response = await globalGetService('/enums/usecaseTypes');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export const getSuspensionCategories = async () => {
  const response = await globalGetService('/enums/suspensionCategories');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export const fetchProfile = async () => {
  const response = await globalGetService('dca/dcas/me');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const getSharingChain = async (campaignUid, params) => {
  const response = await globalGetService(
    `dca/campaigns/${campaignUid}/sharingChain`,
    params
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const getEventHistory = async (query = {}) => {
  const response = await globalGetService('dca/event', {
    ...query,
    sortField: 'createDate',
    ascendingOrder: false,
  });
  if (response && response.status >= 200 && response.status < 300) {
    const eventInfo = response.data;
    eventInfo.recordsPerPage = eventInfo.recordsPerPage
      ? eventInfo.recordsPerPage
      : 10; // handling the backend bug
    return eventInfo;
  }
};

export const waiting = (ms = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const acceptPrimaryDcaCampaign = async (campaignUid) => {
  const response = await globalPutService(
    `dca/campaigns/secondaryDca/${campaignUid}`
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API error');
};

export const rejectPrimaryDcaCampaign = async (campaignUid, explanation) => {
  const response = await globalDeleteService(
    `dca/campaigns/secondaryDca/${campaignUid}`,
    {
      explanation,
    }
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API error');
};

export const suspendCampaign = async (
  campaignUid,
  networkId,
  explanation,
  suspensionCategory
) => {
  const response = await globalPostService(
    `dca/campaigns/${campaignUid}/network/${networkId}/suspend`,
    {
      explanation,
      suspensionCategory,
    }
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API error');
};

export const unsuspendCampaign = async (
  campaignUid,
  networkId,
  explanation
) => {
  const response = await globalPostService(
    `dca/campaigns/${campaignUid}/network/${networkId}/unsuspend`,
    {
      explanation,
    }
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API error');
};

export const fetchAllDcas = async () => {
  const response = await globalGetService('dca/dcas');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const queryCampaignPhoneNumbers = async (id, query = {}) => {
  // call the api silently, don't show error message
  const response = await globalGetService(
    `dca/campaigns/${id}/phoneNumbers`,
    query,
    { silent: true }
  );
  if (response && response.ok) {
    return response.data;
  }
  return null;
};

export const getCampaignPhoneNumbersOnly = async (campaignUid, query = {}) => {
  const response = await globalGetService(
    `dca/campaigns/${campaignUid}/phoneNumbersOnly`,
    query
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    return null;
  }
};

export const downloadCampaignPhoneNumbers = async (campaignUid, query = {}) => {
  const response = await globalGetService(
    `dca/campaigns/${campaignUid}/phoneNumbers/csv`,
    { ...query, timezone: getTimezone() },
    { responseType: 'arraybuffer' }
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    return null;
  }
};

export const getCampaignRoles = async (campaignUid) => {
  try {
    const response = await globalGetService(
      `dca/campaigns/${campaignUid}/roles`
    );
    if (response && response.ok) return response.data;
    return null;
  } catch {
    return null;
  }
};

export const getMigrationCNP = async (campaignUid, params, configs = {}) => {
  const { silent = false } = configs;
  try {
    const response = await globalGetService(
      `dca/campaigns/${campaignUid}/migrateCnp`,
      params,
      { silent }
    );

    if (response && response.ok) {
      return response.data;
    }
    return null;
  } catch {
    return null;
  }
};
