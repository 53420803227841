import { startOfDay, sub } from 'date-fns';
import { debounce } from 'lodash-es';
import {
  Button,
  FilterType,
  Table,
  TableFilter,
  Flex,
  BoxV2,
} from 'portal-commons';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@material-ui/core';

import {
  fetchEventHistory,
  downloadEventHistory,
  getEventTypeOptions,
} from '../apiServices';
import EventsListingRow from '../components/EventsListingRow';
import { MAX_EVENT_RECORDS } from '../constants';
import { withFilters } from '../../../hocs';
import { Loader, FilterToggleButton } from '../../../shared_elements';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../shared_elements/actions';
import { toastFlashMessage } from '../../../utils';
import { generateAndDownloadFile } from '../../../utils/download';
import { globalGetService } from '../../../utils/globalApiServices';
import { convertTimeWithTimezone } from '../../../utils/time';

import '../../../assets/styles/events-listing-module.scss';

const Today = startOfDay(new Date());
const DateRangePresets = [
  {
    label: 'Today',
    start: Today,
    end: Today,
  },
  {
    label: 'Last 7 Days',
    start: sub(Today, { days: 7 }),
    end: Today,
  },
  {
    label: 'Last 1 Month',
    start: sub(Today, { months: 1 }),
    end: Today,
  },
  {
    label: 'Last 6 Months',
    start: sub(Today, { months: 6 }),
    end: Today,
  },
];

const FILTER_CONFIGS = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Type Campaign ID',
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Type Brand ID',
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter brand name',
    suggestions: [],
  },
  cspName: {
    type: FilterType.Text,
    label: 'CSP Name',
    placeholder: 'Type CSP name',
    suggestions: [],
  },
  eventType: {
    type: FilterType.Dropdown,
    label: 'Event Type',
    options: [],
    width: 250,
  },
  dateRange: {
    type: FilterType.DateRange,
    label: 'Date Range',
    placeholder: 'Select date range',
    delayUpdate: false,
    dateRangePresets: DateRangePresets,
  },
};

const DEFAULT_ADDITIONAL_FILTERS = {
  sortField: 'createDate',
  ascendingOrder: false,
};

const headRows = [
  { label: 'EVENT', id: 'eventType', sortable: true },
  { label: 'CATEGORY', id: 'categoryType', sortable: true },
  { label: 'CAMPAIGN ID', id: 'campaignUid', sortable: true },
  { label: 'BRAND ID', id: 'brandUid', sortable: true },
  { label: 'DATE & TIME', id: 'createDate', sortable: true },
  { label: 'DESCRIPTION', id: 'description', sortable: false },
];

const fetchBrandSuggestions = (query) => {
  return globalGetService('dca/brands/suggestions', {
    ...query,
    limit: 20,
  }).then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      return response.data?.map((d) => d?.brandName) ?? [];
    }
  });
};

const fetchCspSuggestions = (query = {}) => {
  return globalGetService('dca/csp/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d.cspName) ?? [];
      }
    }
  );
};

class EventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      eventInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: true,
      eventTypes: [],
      additionalFilters: DEFAULT_ADDITIONAL_FILTERS,
      isFilterVisible: false,
    };
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Events';
    getEventTypeOptions().then((options) => {
      this.props.filter.setOptions('eventType', options);
    });

    this.updateTable();
  }

  dateRangePreprocess = (queries) => {
    if (queries.hasOwnProperty('dateRange')) {
      const dates = queries.dateRange.replace(/\s/gi, '').split('-');
      if (dates.length === 2) {
        queries.startDate = convertTimeWithTimezone(dates[0], 'YYYY-MM-DD');
        queries.endDate = convertTimeWithTimezone(dates[1], 'YYYY-MM-DD');
      }
      delete queries.dateRange;
    }
    return queries;
  };

  updateTable = debounce(() => {
    this.setState({ tableLoader: true });
    let queries = this.parseSearchParams();
    queries = this.dateRangePreprocess(queries);
    fetchEventHistory(queries).then((eventInfo) => {
      this.setState({ tableLoader: false });
      if (!eventInfo) {
        return;
      }

      this.setState((prevState) => ({
        ...prevState,
        eventInfo,
        loader: false,
      }));

      if (
        eventInfo.records.length === 0 &&
        eventInfo.totalRecords > 0 &&
        eventInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(
          eventInfo.totalRecords / eventInfo.recordsPerPage
        );
        this.setState({ loader: true });
        this.writeFiltersToURL({ ...queries, page: lastPageNo });
      }
    });
  }, 200);

  parseSearchParams = () => {
    const { additionalFilters } = this.state;
    const { configs: filterConfigs, appliedFilters } = this.props.filter;
    const queries = queryString.parse(this.props.location.search, {
      decode: true,
    });
    const queryEntries = Object.entries(queries);
    if (queryEntries.length > 0) {
      const newAdditionalFilters = {
        ...additionalFilters,
      };
      queryEntries.forEach(([key, value]) => {
        if (!filterConfigs[key]) {
          if (key === 'sortField') {
            newAdditionalFilters.sortField = value;
          } else if (key === 'ascendingOrder') {
            newAdditionalFilters.ascendingOrder = JSON.parse(value);
          }
        }
      });
      this.setState({
        additionalFilters: newAdditionalFilters,
      });
      return {
        ...appliedFilters,
        ...newAdditionalFilters,
        page: queries.page ? queries.page : 1,
      };
    } else {
      this.setState({
        additionalFilters: DEFAULT_ADDITIONAL_FILTERS,
      });
      return {
        ...DEFAULT_ADDITIONAL_FILTERS,
      };
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateTable();
    }
  }

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.writeFiltersToURL({
      ...appliedFilters,
      ...additionalFilters,
      page: newPage,
    });
  };

  createSortHandler = (sortField) => {
    const { eventInfo, additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));
    if (eventInfo.totalRecords) {
      this.writeFiltersToURL({
        ...appliedFilters,
        ...additionalFilters,
        page: eventInfo.page,
        sortField,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  writeFiltersToURL = (filterObj) => {
    this.props.history.push({
      search: `?${queryString.stringify(filterObj)}`,
    });
  };

  toggleFilterVisibility = () => {
    this.setState((prev) => ({
      isFilterVisible: !prev.isFilterVisible,
    }));
  };

  handleDownload = async () => {
    this.props.setLoadingMessage('We are generating the document');
    let queries = this.parseSearchParams();
    queries = this.dateRangePreprocess(queries);
    if (queries.hasOwnProperty('page')) {
      delete queries.page;
    }
    const response = await downloadEventHistory(queries);
    if (response) {
      const file = new File([response], 'event-history.csv');
      generateAndDownloadFile(file);
    } else {
      toastFlashMessage('Failed to download event history', 'error');
    }
    this.props.clearLoadingMessage();
  };

  render() {
    const {
      eventInfo,
      additionalFilters,
      tableLoader,
      loader,
      isFilterVisible,
    } = this.state;

    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
      handleEdit: handleCandidateFiltersChange,
      handleApply: handleAppliedFiltersChange,
    } = this.props.filter;

    const maskedTotalRecords =
      eventInfo.totalRecords > MAX_EVENT_RECORDS
        ? MAX_EVENT_RECORDS
        : eventInfo.totalRecords;
    const maskedPaginationData = {
      rowsPerPage: eventInfo.recordsPerPage,
      page: eventInfo.page,
      totalRecords: maskedTotalRecords,
    };

    let overLimitDisclaimer;
    if (eventInfo.totalRecords > MAX_EVENT_RECORDS) {
      overLimitDisclaimer = (
        <div className="text-center" style={{ paddingTop: '10px' }}>
          This page can only show the latest {MAX_EVENT_RECORDS} records out of
          the total of {eventInfo.totalRecords}. Contact support for details on
          your remaining records.
        </div>
      );
    }
    return (
      <section
        className="events-listing-section"
        style={{ padding: '0px' }}
        data-testid="eventsList"
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            <Flex
              sx={{
                alignItems: 'center',
                mb: '8px',
                fontWeight: 600,
                fontSize: 'H600',
                lineHeight: '32px',
                color: 't.black90',
              }}
            >
              <span>
                {!loader && eventInfo.totalRecords !== 0
                  ? `${eventInfo.totalRecords} Events`
                  : 'Events'}
              </span>
              <BoxV2
                sx={{
                  mt: '5px',
                  ml: '12px',
                  fontWeight: 400,
                  fontSize: 'H200',
                  lineHeight: '14px',
                  color: 't.black60',
                }}
              >
                Results capped at first 10,000 records
              </BoxV2>
            </Flex>
            <Flex sx={{ alignItems: 'center', gap: 's', mb: 'xs' }}>
              <FilterToggleButton
                visible={isFilterVisible}
                onClick={this.toggleFilterVisibility}
              />
              <Button
                variant="outline"
                onClick={this.handleDownload}
                data-testid="tableDownloadButton"
                style={{
                  borderColor: '#FE772D',
                  color: '#B2531F',
                  padding: '12px 16px',
                }}
              >
                <FontAwesomeIcon icon={faArrowDownToLine} />
                Download
              </Button>
            </Flex>
            <Container maxWidth={false} style={{ padding: '0px' }}>
              {isFilterVisible && (
                <TableFilter
                  portal="dca"
                  configs={filterConfigs}
                  candidateValues={candidateFilters}
                  appliedValues={appliedFilters}
                  onCandidateValuesChange={handleCandidateFiltersChange}
                  onAppliedValuesChange={handleAppliedFiltersChange}
                  data-testid="eventListTableFilter"
                  className="filters"
                />
              )}
              <Flex
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  mt: 'm',
                }}
              >
                <Table
                  testId="eventListingTable"
                  disableHover
                  headRows={headRows}
                  emptyState="no events to view"
                  records={{ total: eventInfo.totalRecords }}
                  loading={tableLoader}
                  tableData={eventInfo.records.map((record, index) => {
                    return (
                      <EventsListingRow
                        key={`${index}_${record.id}`}
                        event={record}
                      />
                    );
                  })}
                  handleChangePage={this.handleChangePage}
                  createSortHandler={this.createSortHandler}
                  filter={additionalFilters}
                  pagination={maskedPaginationData}
                />
                {Math.ceil(MAX_EVENT_RECORDS / eventInfo.recordsPerPage) ===
                eventInfo.page
                  ? overLimitDisclaimer
                  : null}
              </Flex>
            </Container>
          </>
        )}
      </section>
    );
  }
}

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoadingMessage: (message) =>
    dispatch({
      type: SET_LOADING_MESSAGE,
      payload: message,
    }),
  clearLoadingMessage: () =>
    dispatch({
      type: CLEAR_LOADING_MESSAGE,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFilters(EventsList, {
    configs: FILTER_CONFIGS,
    loaders: {
      suggestion: {
        brandName: {
          minLength: 2,
          load: (value) => fetchBrandSuggestions({ prefix: value }),
        },
        cspName: {
          minLength: 2,
          load: (value) => fetchCspSuggestions({ prefix: value }),
        },
      },
    },
  })
);
