const isPasswordRequirementsError = (message: string) => {
  return message.includes(
    'HTTP 400, Okta E0000001 (Api validation failed: password - password: Password requirements were not met. Password requirements: at least 12 characters, a lowercase letter, an uppercase letter, a number, a symbol, no parts of your username, does not include your first name, does not include your last name. Your password cannot be any of your last 5 passwords.)'
  );
};

export const extractErrorMessages = (errors: Record<string, string[]>) => {
  const messages: string[] = [];
  Object.keys(errors).forEach((key) => {
    errors[key].forEach((message) => {
      if (message) {
        if (isPasswordRequirementsError(message)) {
          messages.push('Password requirements are not met');
        } else {
          messages.push(message);
        }
      }
    });
  });
  return messages;
};
