import { useState, useEffect } from 'react';
import { UserData } from '../../types';
import { fetchUsers } from '../../apiServices';

const useUserData = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchUsers();
      setUsers(data);
    } catch (error) {
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    users,
    userCount: users.length,
    loading,
    refetch: fetchData,
  };
};

export default useUserData;
