import { isEqual } from 'lodash-es';
import { BoxV2 as Box } from 'portal-commons';
import React, { useState, useEffect } from 'react';

import { fetchProfile, updateProfile } from '../apiServices';
import {
  UserProfileBasicInfo,
  EmailPreferences,
  Enablements,
  UserManagement,
  AccountUsage,
} from '../components';
import { UserProfile } from '../types';
import { toastFlashMessage } from '../../../utils';
import useUserData from '../components/UserManagement/useUserData';

const UsersProfile: React.FC = () => {
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [escalateEmails, setEscalateEmails] = useState<string[]>([]);
  const [loadingEmail, setLoadingEmail] = useState(true);
  const { users, userCount, loading, refetch } = useUserData();

  const fetchProfileAndEmails = async () => {
    const profile = (await fetchProfile()) as UserProfile;
    if (profile) {
      setUserProfile(profile);
      const emails = profile.escalateEmail.split(',');
      setEscalateEmails(emails.filter((mail: string) => mail !== ''));
    }
    setLoadingEmail(false);
  };

  const updateEscalateEmails = async (emails: string[]) => {
    if (isEqual(emails, escalateEmails)) return;

    await updateProfile({ escalateEmail: emails.join(',') });
    toastFlashMessage('Email preferences updated successfully', 'success');
    setEscalateEmails(emails);
  };

  useEffect(() => {
    fetchProfileAndEmails();
  }, []);

  return (
    <Box pt="xxl" data-testid="users">
      <UserProfileBasicInfo user={userProfile} />

      <EmailPreferences
        loading={loadingEmail}
        emails={escalateEmails}
        onChange={updateEscalateEmails}
      />

      <Enablements profile={userProfile} />

      <AccountUsage
        profile={userProfile}
        loading={loading}
        userCount={userCount}
      />

      <UserManagement
        profile={userProfile}
        users={users}
        loading={loading}
        fetchUsers={refetch}
      />
    </Box>
  );
};

export default UsersProfile;
