import {
  Flex,
  BoxV2 as Box,
  Button,
  TextInput,
  DropdownV2 as Dropdown,
} from 'portal-commons';
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { faInfo, faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { validateRules } from './validateRules';
import { createUser, updateUser, deleteUser } from '../../apiServices';
import { UserData, SelectOption } from '../../types';
import { Role } from '../../../../types';
import { toastFlashMessage } from '../../../../utils';

const CustomDialog = withStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      width: '525px',
      maxWidth: '525px',
    },
  },
}))(Dialog);

interface UserForm {
  firstName: string;
  lastName: string;
  email: string;
  userAppRole: string;
}

interface Props {
  open: boolean;
  mode: 'new' | 'edit' | 'delete';
  user?: UserData;
  roleOptions: SelectOption[];
  onClose: (fetchUser?: boolean, delay?: boolean) => void;
}
const UserModal: React.FC<Props> = ({
  open,
  mode,
  user,
  roleOptions,
  onClose,
}) => {
  const defaultValues: UserForm = {
    firstName: user?.profile.firstName || '',
    lastName: user?.profile.lastName || '',
    email: user?.profile.email || '',
    userAppRole: user?.profile.dcaUserRole
      ? user.profile.dcaUserRole[0]
      : mode === 'edit'
      ? Role.Viewer
      : '',
  };
  const { control, getValues, clearErrors, trigger } = useForm<UserForm>({
    defaultValues,
    mode: 'onBlur',
  });

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (mode === 'delete') return;
    clearErrors('root');
  }, []);

  const handleDeleteUser = async () => {
    if (!user) return;
    setLoader(true);
    const result = await deleteUser(user.id);
    setLoader(false);
    if (result) {
      toastFlashMessage('User deleted successfully', 'success');
      onClose(true);
    }
  };

  const handleAddNewUser = async (data: UserForm) => {
    setLoader(true);
    const result = await createUser(data);
    setLoader(false);
    if (result) {
      toastFlashMessage('New user added successfully', 'success');
      onClose(true);
    }
  };

  const handleUpdateUser = async (data: UserForm) => {
    if (data.userAppRole === defaultValues.userAppRole) {
      toastFlashMessage('User is already in the selected role', 'success');
      onClose(true);
    } else {
      if (!user) return;
      setLoader(true);
      const result = await updateUser(user.id, {
        userAppRole: data.userAppRole,
      });
      setLoader(false);
      if (result) {
        toastFlashMessage('User role updated successfully', 'success');
        // Need fetch user again and delay few seconds wait Okta data update
        onClose(true, true);
      }
    }
  };

  const handleSubmit = async () => {
    if (mode === 'delete') {
      handleDeleteUser();
      return;
    }

    const isValid = await trigger();
    if (!isValid) return;

    const data = getValues();
    if (mode === 'new') {
      handleAddNewUser(data);
    } else if (mode === 'edit') {
      handleUpdateUser(data);
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={() => onClose()}
      maxWidth={false}
      aria-labelledby="user-modal"
      aria-describedby="user modal for add new user or edit, delete user"
      data-testid="userModal"
    >
      <Box
        sx={{
          maxWidth: '525px',
          p: 'xxl',
          fontFamily: 'normal',
          lineHeight: 'normal',
        }}
      >
        {mode === 'delete' ? (
          <Box
            sx={{
              mb: 'xxl',
              fontSize: 'H500',
              fontWeight: 400,
            }}
          >
            Do you want to <strong>permanently delete</strong> user{' '}
            {`${user?.profile.firstName} ${user?.profile.lastName}`}?
          </Box>
        ) : (
          <>
            <Flex
              sx={{
                mb: 's',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 'H600',
                fontWeight: 600,
              }}
            >
              <FontAwesomeIcon
                icon={faCircleUser}
                style={{ marginRight: '8px', fontSize: '24px' }}
              />
              <Box>{mode === 'new' ? 'New' : 'Edit'} User</Box>
            </Flex>
            <Flex
              sx={{ flexDirection: 'column', gap: 's' }}
              data-testid="userActionForm"
            >
              {mode === 'edit' && (
                <Box
                  sx={{
                    fontSize: 'H400',
                    fontWeight: 500,
                    my: 's',
                    textAlign: 'center',
                  }}
                >
                  USER ID: {user?.id}
                </Box>
              )}
              {mode === 'new' && (
                <Box sx={{ fontSize: 'H100', fontWeight: 400 }}>
                  * Indicates a Required Field
                </Box>
              )}
              <Controller
                name="firstName"
                control={control}
                rules={validateRules.firstName as any}
                render={({ field, fieldState: { error = {} } }) => (
                  <TextInput
                    label="First Name"
                    placeholder="First Name"
                    data-testid="userActionFormFirstNameInput"
                    required
                    readOnly={mode === 'edit' || loader}
                    error={!!error.message}
                    note={error.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={validateRules.lastName as any}
                render={({ field, fieldState: { error = {} } }) => (
                  <TextInput
                    label="Last Name"
                    placeholder="Last Name"
                    data-testid="userActionFormLastNameInput"
                    required
                    readOnly={mode === 'edit' || loader}
                    error={!!error.message}
                    note={error.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={validateRules.email as any}
                render={({ field, fieldState: { error = {} } }) => (
                  <TextInput
                    label="Email"
                    placeholder="Email"
                    data-testid="userActionFormEmailInput"
                    required
                    readOnly={mode === 'edit' || loader}
                    error={!!error.message}
                    note={error.message}
                    {...field}
                  />
                )}
              />
              <Flex
                sx={{ alignItems: 'flex-start', gap: 's' }}
                data-testid="userActionFormUserAppRole"
              >
                <Controller
                  name="userAppRole"
                  control={control}
                  rules={validateRules.userAppRole as any}
                  render={({ field, fieldState: { error = {} } }) => (
                    <Dropdown
                      label="Role"
                      required
                      options={roleOptions}
                      readOnly={loader}
                      error={!!error.message}
                      helperText={error.message}
                      data-testid="userActionFormUserAppRoleSelect"
                      {...field}
                    />
                  )}
                />
                <RoleTooltip />
              </Flex>
            </Flex>
          </>
        )}
        <Flex
          sx={{
            justifyContent: 'center',
            alignItem: 'center',
            gap: 'm',
            mt: 'xxl',
          }}
        >
          <Button
            variant="outline"
            onClick={() => onClose()}
            disabled={loader}
            data-testid={
              mode === 'delete'
                ? 'deleteUserDialogCancelButton'
                : 'userActionFormCancelButton'
            }
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loader}
            data-testid={
              mode === 'delete'
                ? 'deleteUserDialogDeleteButton'
                : 'userActionFormSubmitButton'
            }
          >
            {mode === 'delete' ? 'Delete' : 'Save'}
          </Button>
        </Flex>
      </Box>
    </CustomDialog>
  );
};

const CustomTooltip = withStyles(() => ({
  arrow: {
    color: '#0091B3',
  },
  tooltip: {
    background: '#0091B3',
    color: '#fff',
    fontSize: 12,
    borderRadius: '3px',
    maxWidth: 400,
    fontFamily: 'Roboto',
    padding: '5px 15px',
    fontWeight: 400,
  },
}))(Tooltip);
const RoleTooltip: React.FC = () => {
  return (
    <CustomTooltip
      arrow
      placement="right"
      title={
        <Flex sx={{ flexDirection: 'column', gap: 'xs', fontSize: 'H200' }}>
          <p>
            <strong>Viewer:</strong> Can view campaigns, DCA rules, and the DCA
            profile. No access to Integrations page. Cannot manage users.
          </p>
          <p>
            <strong>Reviewer:</strong> Can view campaigns, DCA rules, and the
            DCA profile. Able to accept or decline shared campaigns and elect a
            secondary DCA. No access to Integrations page. Cannot manage users.
          </p>
          <p>
            <strong>User:</strong> Can view campaigns, DCA rules, and the DCA
            profile. Full access to campaign sharing. Can access Integrations
            page. Cannot manage users.
          </p>
          <p>
            <strong>Manager:</strong> Full access to campaigns, DCA rules,
            campaign sharing, and DCA profile information. Can access
            Integrations page. Can add and edit users.
          </p>
        </Flex>
      }
    >
      <FontAwesomeIcon
        icon={faInfo}
        style={{ color: '00698F', marginTop: 12 }}
        fontSize={14}
      />
    </CustomTooltip>
  );
};

export default UserModal;
