import { Table, Flex, BoxV2 as Box, Button, RoleGuard } from 'portal-commons';
import React, { useMemo, useState, useEffect } from 'react';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import MfaBlk from '../MfaBlk';
import UsersListingRow from '../UsersListingRow';
import UserModal from '../UserModal';
import { fetchUserRoles } from '../../apiServices';
import { UserData, SelectOption, UserProfile } from '../../types';
import { parseRoleLabel } from '../../utils';
import { Role } from '../../../../types';

const useStyles = makeStyles(() => ({
  userTable: {
    minHeight: 44,
  },
}));

const headRows = [
  { id: 'fullName', label: 'NAME', sortable: true },
  { id: 'email', label: 'E-MAIL', sortable: false },
  { id: 'dcaUid', label: 'USER ID', sortable: false },
  { id: 'dcaUserRole', label: 'ROLE', sortable: true },
  { id: 'created', label: 'CREATED ON', sortable: true },
  { id: 'lastLogin', label: 'LAST LOGIN', sortable: true },
  { id: 'action', label: '', sortable: false },
];

interface UserDataExtended extends UserData {
  fullName: string;
  email: string;
  dcaUid: string;
  dcaUserRole: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
const convertUserToExtended = (data: UserData[]): UserDataExtended[] => {
  return data.map((user: UserData) => ({
    ...user,
    fullName: user.profile.fullName,
    email: user.profile.email,
    dcaUid: user.profile.dcaUid,
    dcaUserRole: user.profile.dcaUserRole
      ? user.profile.dcaUserRole[0]
      : Role.Viewer,
  }));
};

const UserManagement: React.FC<{
  profile?: UserProfile;
  users: UserData[];
  loading: boolean;
  fetchUsers: () => void;
}> = ({ profile, users, loading, fetchUsers }) => {
  const classes = useStyles();
  const [maxUsers, setMaxUsers] = useState(profile?.maxPortalUsers ?? 10);
  const [waitTimeout, setWaitTimeout] = useState(false);
  const [modalMode, setModalMode] = useState<'new' | 'edit' | 'delete'>();
  const extendedUsers = useMemo(() => convertUserToExtended(users), [users]);
  const pageSize = 10;
  const [filter, setFilter] = useState({
    sortField: '',
    ascendingOrder: false,
  });
  const [currPage, setCurrPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (profile) {
      setMaxUsers(profile.maxPortalUsers);
    }
  }, [profile]);

  const getUsers = async (delay = false) => {
    setWaitTimeout(true);
    // TCR-12401: Workaround for Okta update user delay
    delay && (await new Promise((resolve) => setTimeout(resolve, 2000)));
    setWaitTimeout(false);
    await fetchUsers();
  };

  const handleCloseModal = (fetchUser = false, delay = false) => {
    setSelectedUser(undefined);
    setModalMode(undefined);
    fetchUser && getUsers(delay);
  };

  const handleSortField = (field: string) => {
    setFilter((prev) => ({
      ...prev,
      sortField: field,
      ascendingOrder: prev.sortField === field ? !prev.ascendingOrder : true,
    }));
  };

  useEffect(() => {
    getUsers();

    fetchUserRoles().then((data) => {
      const options: SelectOption[] = data.map((userRole: string) => {
        return {
          label: parseRoleLabel(userRole),
          value: userRole,
        };
      });
      setRoleOptions(options);
    });
  }, []);

  const displayUsers = useMemo(() => {
    if (extendedUsers.length === 0) return [];

    let tempData = extendedUsers.slice();
    if (filter.sortField) {
      tempData = tempData.sort((a, b) => {
        const { sortField, ascendingOrder } = filter;
        const dataA = a[sortField];
        const dataB = b[sortField];
        if (typeof dataA === 'string' && typeof dataB === 'string') {
          return ascendingOrder
            ? (dataA as string).localeCompare(dataB)
            : (dataB as string).localeCompare(dataA);
        } else if (typeof dataA === 'number' && typeof dataB === 'number') {
          return ascendingOrder ? dataA - dataB : dataB - dataA;
        } else {
          return 0;
        }
      });
    }
    if (
      tempData.length === extendedUsers.length &&
      tempData.length > pageSize
    ) {
      const start = (currPage - 1) * pageSize;
      const end = start + pageSize;
      tempData = tempData.slice(start, end);
    }
    return tempData;
  }, [extendedUsers, filter, pageSize, currPage]);

  return (
    <Box
      sx={{
        mt: 'xxl',
        p: 'xxl',
        border: '1px solid #C0CCD4',
        fontFamily: 'normal',
        color: 't.grey500',
        lineHeight: 'normal',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          fontSize: 'H300',
          lineHeight: 'normal',
          width: '100%',
          pb: 'm',
          borderBottom: '1px solid',
          borderColor: '#CCCFD0',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            fontSize: 'H500',
            fontWeight: 600,
          }}
        >
          <FontAwesomeIcon
            icon={faCircleUser}
            style={{ marginRight: '8px' }}
            fontSize={24}
          />
          <Box sx={{ fontSize: 'H500' }} as="h2">
            User Management
          </Box>
        </Flex>
        <RoleGuard feature="profile.addUser">
          <Button
            shape="square"
            size="small"
            disabled={users.length >= maxUsers}
            onClick={() => {
              setModalMode('new');
            }}
            data-testid="createNewUserButton"
          >
            Create New User
          </Button>
        </RoleGuard>
      </Flex>
      <Box sx={{ my: 'l' }}>
        <MfaBlk profile={profile} />
      </Box>

      <Table
        className={classes.userTable}
        testId="usersTable"
        loading={loading || waitTimeout}
        headRows={headRows}
        disableHover
        emptyState="No users"
        data={displayUsers}
        filter={filter}
        renderRow={(data) => {
          return (
            <UsersListingRow
              user={data as unknown as UserData}
              key={data.id as string}
              onEditUser={(user) => {
                setModalMode('edit');
                setSelectedUser(user);
              }}
              onDeleteUser={(user) => {
                setModalMode('delete');
                setSelectedUser(user);
              }}
            />
          );
        }}
        handleChangePage={(page) => setCurrPage(page)}
        createSortHandler={handleSortField}
        pagination={{
          page: currPage,
          rowsPerPage: pageSize,
          totalRecords: users.length,
        }}
        emptyBlockHeight="160px"
      />
      {users.length >= maxUsers && (
        <Box sx={{ mt: 'l', textAlign: 'center' }}>
          Maximum number of users reached!
          <br />
          Please delete existing users if you wish to create a new one.
        </Box>
      )}

      {modalMode && (
        <UserModal
          open={!!modalMode}
          mode={modalMode}
          user={selectedUser}
          roleOptions={roleOptions}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default UserManagement;
