import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BoxV2 as Box, Flex } from 'portal-commons';
import React from 'react';
import { UserProfile } from '../../types';
import Field from '../EmailPreferences/Field';

interface Props {
  profile?: UserProfile;
  loading: boolean;
  userCount: number;
}

const AccountUsage: React.FC<Props> = ({ profile, loading, userCount }) => {
  return (
    <Box
      sx={{
        mt: 'xxl',
        p: 'xxl',
        border: '1px solid #C0CCD4',
        fontFamily: 'normal',
        color: 't.grey500',
        lineHeight: 'normal',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '100%',
          pb: 'm',
          borderBottom: '1px solid',
          borderColor: '#CCCFD0',
        }}
      >
        <Flex sx={{ alignItems: 'center', fontSize: 'H500', fontWeight: 600 }}>
          <FontAwesomeIcon
            icon={faCircleUser}
            style={{ marginRight: '8px', fontSize: '24px' }}
          />
          <Box sx={{ fontSize: 'H500' }} as="h2">
            Account Usage
          </Box>
        </Flex>
        <Box sx={{ mt: 'l', fontSize: 'H300' }}>
          Portal users can be added in increments of 10 with an associated cost.
          Please reach out to{' '}
          <a
            href="mailto: support@campaignregistry.com"
            style={{ color: '#0091B3', textDecoration: 'underline' }}
          >
            support@campaignregistry
          </a>{' '}
          for information.
        </Box>
      </Flex>
      <Box
        sx={{
          mt: 'xs',
          fontSize: 'H300',
          fontWeight: 400,
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          py: 's',
          rowGap: 's',
        }}
      >
        <Field
          label="Max Portal Users"
          loading={loading}
          value={`${userCount} / ${profile?.maxPortalUsers ?? 10}`}
          data-testid="accountUsageMaxPortalUsers"
        />
      </Box>
    </Box>
  );
};

export default AccountUsage;
