import {
  ComponentType,
  FunctionComponent,
  createContext,
  useState,
  useContext,
} from 'react';

import { DcaProfile, Role, User } from '../types';
import {
  restoreProfile,
  restoreUser,
  saveProfile,
  saveUser,
} from '../utils/storage';

interface AuthContextProps {
  user?: User;
  role?: Role;
  profile?: DcaProfile;
  setUser: (user: User) => void;
  setProfile: (profile: DcaProfile) => void;
}

const parseRole = (user?: User) => {
  return user?.profile
    ? user?.profile.dcaUserRole?.[0] ?? Role.Viewer
    : undefined;
};

const AuthContext = createContext<AuthContextProps>({
  /* eslint-disable @typescript-eslint/no-empty-function */
  setUser: () => {},
  setProfile: () => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
});

export const AuthContextProvider: FunctionComponent = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(restoreUser());
  const [role, setRole] = useState<Role | undefined>(parseRole(restoreUser()));
  const [profile, setProfile] = useState<DcaProfile | undefined>(
    restoreProfile()
  );

  const handleUserChange = (user: User) => {
    const role = parseRole(user);
    setUser(user);
    setRole(role);
    saveUser(user);
  };

  const handleProfileChange = (profile: DcaProfile) => {
    setProfile(profile);
    saveProfile(profile);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        role,
        profile,
        setUser: handleUserChange,
        setProfile: handleProfileChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export const withAuthContext =
  <P extends Record<string, never>>(WrappedComponent: ComponentType<P>) =>
  (props: P) =>
    (
      <AuthContext.Consumer>
        {(contexts) => <WrappedComponent {...props} {...contexts} />}
      </AuthContext.Consumer>
    );
