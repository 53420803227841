import { globalGetService } from '../../utils/globalApiServices';

export async function getCampaignDetail(campaignUid, params = {}) {
  const response = await globalGetService(
    `dca/campaigns/${campaignUid}`,
    params,
    { silent: true }
  );
  if (response && response.ok) {
    return response.data;
  }
  return null;
}

export async function getCampaignByPhone(phoneNumber) {
  const response = await globalGetService(
    `dca/campaigns/byPhone/${phoneNumber}`,
    {},
    { silent: true }
  );
  if (response && response.ok) {
    return response.data;
  }
  return null;
}
